<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 考试证书 </template>
      <template #input> </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <div class="box_top">
        <a-tabs default-active-key="1" @change="callback">
          <a-tab-pane
            :key="item.configId"
            v-for="item in tabData"
            :tab="item.abbreviation"
          ></a-tab-pane>
        </a-tabs>
        <div class="top_input">
          <a-input v-model="courseName" placeholder="请输入关键字" />
          <a-button
            type="primary"
            class="btn"
            :loading="loading"
            @click="search()"
            >搜索</a-button
          >
          <a-button
            type="primary"
            class="btn"
            @click="goPage(examId, undefined)"
            v-hasPermi="['certificates:examcertif:add']"
            >证书生成</a-button
          >
        </div>
      </div>

      <a-table
        class="table-template"
        :scroll="{ x: true }"
        :rowKey="(item) => item.id"
        :columns="columns"
        :loading="loading"
        :data-source="tableData"
        @change="onPage"
        :pagination="{
          total: total,
          current: pageNumber,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (pageNumber - 1) * pageSize + i + 1 }}
          </div>
        </template>
        <!-- 考试场次 -->
        <template slot="timeNames" slot-scope="text">
          <p v-for="(item, index) in JSON.parse(text)" :key="index + 'time'">
            {{ item }}
          </p>
        </template>
        <!-- 考试日期 -->
        <template slot="startDate" slot-scope="text">
          <p v-for="(item, index) in text" :key="index + 's'">
            {{ item }}
          </p>
        </template>

        <!-- 考试人数 -->
        <template slot="Exam" slot-scope="text, record">
          <div class="btn_router_link">
            <span @click="goLearnPage(record.id)">{{ record.examNum }}</span>
          </div>
        </template>
        <!-- 证书名称 -->
        <template slot="certifTempName" slot-scope="text, record">
          <span>{{ record.certifTempName }}</span>
        </template>
        <!-- 获证人数 -->
        <template slot="obtain" slot-scope="text, record">
          <div class="btn_router_link">
            <span @click="goObtainPage(record.id)">{{ record.certifNum }}</span>
          </div>
        </template>
        <template slot="operation" slot-scope="item, row">
          <div class="btn_router_link">
            <span @click="goPage(examId, row.id)">编辑</span>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
    fixed: "left",
  },
  {
    title: "课程名称",
    align: "center",
    dataIndex: "courseName",
  },
  {
    title: "考试场次",
    align: "center",
    width: 240,
    dataIndex: "timeName",
    scopedSlots: { customRender: "timeNames" },
  },
  {
    title: "考试日期",
    align: "center",
    scopedSlots: { customRender: "startDate" },
    dataIndex: "startDate",
  },
  {
    title: "证书名称",
    align: "center",
    dataIndex: "certifTempName ",
    scopedSlots: { customRender: "certifTempName" },
  },
  {
    title: "考试人数",
    align: "center",
    dataIndex: "examNum ",
    scopedSlots: { customRender: "Exam" },
  },
  {
    title: "获证人数",
    align: "center",
    dataIndex: "certifNum ",
    scopedSlots: { customRender: "obtain" },
  },
  {
    title: "证书生成",
    align: "center",
    dataIndex: "status",
  },
  {
    title: "证书物流",
    align: "center",
    dataIndex: "shipStatus",
  },
  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "operation" },
    fixed: "right",
  },
];
import HeaderBox from "@/components/HeaderBox.vue";

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      columns, // 表头
      tableData: [], // 列表数据
      tabData: [], // 签页数据
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      courseName: null,
      examId: 1,
    };
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
      this.getData();
    },
    // tab切换
    callback(e) {
      this.examId = e;
      this.pageNumber = 1;
      this.getData();
    },
    // 证书生成
    goPage(e, r) {
      this.$router.push({
        path: "/admin/Certificate/CandidateInformation",
        query: { configId: e, recordId: r },
      });
    },
    // 搜索
    search() {
      this.pageNumber = 1;
      this.pageSize = 10;
      this.getData();
    },
    // 考试学习人员
    goLearnPage(e) {
      this.$router.push({
        path: "/admin/Certificate/ExaminationLearners",
        query: { recordId: e },
      });
    },
    // 获证人数详情页
    goObtainPage(e) {
      this.$router.push({
        path: "/admin/Certificate/ExaminationCertificate",
        query: { recordId: e },
      });
    },
    // 获取课程数据
    gettablist() {
      this.$ajax({
        url: "/hxclass-management/exam/config/exam/tab/list",
        method: "get",
        // params: {
        //   current: this.pageNumber,
        //   size: this.pageSize,
        //   courseName: this.courseName,
        // },
      }).then((res) => {
        this.tabData = res.data;
        this.examId = res.data[0].configId;
        this.getData();
      });
    },
    // 获取数据
    getData() {
      this.loading = true;
      this.$ajax({
        url: "/hxclass-management/exam/certif/list",
        method: "get",
        params: {
          current: this.pageNumber,
          size: this.pageSize,
          courseName: this.courseName,
          examId: this.examId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.loading = false;
          this.tableData = res.data.records;
          this.total = res.data.total;
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.gettablist();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.btn {
  margin-right: 24px;
}
.table-box {
  .box_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .top_input {
      display: flex;
      align-items: center;
    }
    input {
      width: 240px;
      margin-right: 24px;
    }
  }
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}
.table-template {
  margin-top: 10px;
}
/deep/.right-box {
  padding-bottom: 5px !important;
}
.modalDiv {
  > p {
    font-weight: 500;
    color: #333333;
  }
  .code-item {
    > span {
      display: inline-block;
      min-width: 100px;
      margin: 5px 0;
      margin-right: 15px;
    }
  }
}
.btn_router_link {
  cursor: pointer;
}
</style>
